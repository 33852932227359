import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Seo from '../../components/seo';

// import PartnerScreen from '../screens/page/partner';
import usePreviewData from '../../utils/usePreviewData';
import { device } from '../../utils';
import Title from '../../components/title';
import TutoGrid from '../../components/tuto/TutoGrid';

const Wrapper = styled.div`
  background-color: #000;
  width: 100%;

  .container {
    margin-top: 30px;
    margin-bottom: 30px;

    @media ${device.tablet} {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media ${device.desktop} {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }

  .carousel {
    padding: 0;
  }

  .title {
    color: #FFF;
  }
`;


const PageTemplate = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const gPage = data.page || data.pageTips || data.pageGammes;
  const page = gPage.data;
  const pages = data.pages.nodes;
  const items = [...pages.map((page) => ({
    id: page.id,
    link: { url: page.url },
    image: page.data.thumbnail?.url ? page.data.thumbnail : page.data.cover_mobile,
  })), ...data.landings.nodes.map((page) => ({
    id: page.id,
    link: { url: page.url },
    image: page.data.thumbnail?.url ? page.data.thumbnail : page.data.cover_mobile,
  }))];

  return (
    <>
      <Seo title={page.title} description={page?.seo_description} />
      <Wrapper>
        <div className="container">
          {page.title && (
            <Title title={page.title} as="h2" className="title" />
          )}
          <TutoGrid
            items={items}
            preloadHead
          />
        </div>
      </Wrapper>
    </>
  );
};

export const query = graphql`
  query ($prismic: ID!, $cmsLocaleGlob: String!, $filterTag: String!) {
    page: prismicHomeTuto(prismicId: { eq: $prismic }) {
      data {
        seo_description
        title
      }
    }
    pageTips: prismicHomeTips(prismicId: { eq: $prismic }) {
      data {
        seo_description
        title
      }
    }
    pageGammes: prismicHomeGammes(prismicId: { eq: $prismic }) {
      data {
        seo_description
        title
      }
    }
    pages: allPrismicTuto(
      filter: {
        lang: { glob: $cmsLocaleGlob }
        tags: { in: [$filterTag] }
        #data: { thumbnail: { url: { ne: null } } }
      }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        id
        url
        data {
          thumbnail {
            dimensions {
              height
              width
            }
            alt
            url
          }
          cover_mobile {
            dimensions {
              height
              width
            }
            alt
            url
          }
        }
      }
    }
    landings: allPrismicLandingPage(
      filter: {
        lang: { glob: $cmsLocaleGlob }
        tags: { in: [$filterTag] }
        #data: { thumbnail: { url: { ne: null } } }
      }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      nodes {
        id
        url
        data {
          thumbnail {
            dimensions {
              height
              width
            }
            alt
            url
          }
        }
      }
    }
  }
`;
export default PageTemplate;
